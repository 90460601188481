import React from "react";
import BannerSectionStyle3 from "../Section/BannerSection/BannerSectionStyle3";
import BannerSectionStyle4 from "../Section/BannerSection/BannerSectionStyle4";
import Section from "../Section";
import DepartmentSectionStyle2 from "../Section/DepartmentSection/DepartmentSectionStyle2";
import FeaturesSectionStyle2 from "../Section/FeaturesSection/FeaturesSectionStyle2";
import FunFactSection from "../Section/FunFactSection";
import TeamSection from "../Section/TeamSection";
import GallerySection from "../Section/GallerySection";
import AwardSectionStyle2 from "../Section/AwardSection/AwardSectionStyle2";
import { pageTitle } from "../../helpers/PageTitle";
const departmentData = [
  {
    title: "Medical Billing Coding",
    subTitle:
      "Accurate coding to ensure proper reimbursement. Detailed claim submission and tracking. Handling of denied claims to maximize your revenue.",
    iconUrl: "/images/icons/calendar_white.svg",
    href: "/services/medical-billing-and-coding",
  },
  {
    title: "Clearinghouse",
    subTitle:
      "Streamline claims processing with our efficient clearinghouse services.",
    iconUrl: "/images/icons/calendar_white.svg",
    href: "/services/clearinghouse-services",
  },
  {
    title: "Credentialing",
    subTitle:
      "Simplify the credentialing process with our expert services.",
    iconUrl: "/images/icons/calendar_white.svg",
    href: "/services/credentialing-services",
  },
  {
    title: "Compliance and Regulatory Services",
    subTitle:
      "Ensuring your practice adheres to the latest healthcare regulations. HIPAA-compliant processes to protect patient information.",
    iconUrl: "/images/icons/calendar_white.svg",
    href: "/services/compliance-and-regulatory-services",
  },
  {
    title: "Insurance Verification and Authorizations",
    subTitle:
      "Verification of patient insurance coverage before services are provided. Obtaining necessary authorizations to prevent payment delays.",
    iconUrl: "/images/icons/calendar_white.svg",
    href: "/services/insurance-verification-and-authorizations",
  },
];

const featureListData = [
  {
    title: "Our Vision",
    subTitle:
      "To be the leading partner in medical billing and revenue cycle management, driving excellence through technology and expertise.",
    iconUrl: "images/icons/professional.svg",
  },
  {
    title: "Our Mission",
    subTitle:
      "To provide comprehensive and reliable medical billing solutions that empower healthcare providers to focus on delivering outstanding patient care while optimizing their revenue cycle.",
    iconUrl: "images/icons/comprehensive.svg",
  },
  {
    title: "Our Values",
    subTitle:
      "We uphold the highest standards of ethical conduct and transparency. We embrace cutting-edge technology to drive efficiency and accuracy. We are committed to understanding and addressing the unique needs of each client.",
    iconUrl: "images/icons/patient.svg",
  },
  {
    title: "Our Team",
    subTitle:
      "ABC Billings is driven by a team of dedicated experts committed to delivering exceptional results. Our staff is highly trained and stays current with the latest developments in healthcare billing, technology, and regulatory compliance.",
    iconUrl: "images/icons/facilities.svg",
  },
];

const funFactData = [
  { number: "20+", title: "Years of experience" },
  { number: "95%", title: "Patient satisfaction rating" },
  { number: "5000+", title: "Patients served annually" },
  { number: "10+", title: "Healthcare providers on staff" },
  { number: "22+", title: "Convenient locations in the area" },
];

const teamData = [
  {
    imgUrl: "images/about/doctor_1.png",
    name: "Dr. James Lee, MD",
    designation: "Head of Cardiologist",
    description:
      "With expertise in managing complex heart conditions and performing advanced cardiac procedures",
    social: [
      { icon: "fa6-brands:facebook-f", href: "/about" },
      { icon: "fa6-brands:linkedin-in", href: "/about" },
      { icon: "fa6-brands:twitter", href: "/about" },
    ],
  },
  {
    imgUrl: "images/about/doctor_2.png",
    name: "Dr. John Smith, MD",
    designation: "Emergency Medicine Physician",
    description:
      "With expertise in treating acute illnesses and injuries in medicine physician",
    social: [
      { icon: "fa6-brands:facebook-f", href: "/about" },
      { icon: "fa6-brands:linkedin-in", href: "/about" },
      { icon: "fa6-brands:twitter", href: "/about" },
    ],
  },
  {
    imgUrl: "images/about/doctor_3.png",
    name: "Dr. Susan Bones, MD",
    designation: "Board-certified Pediatrician",
    description:
      "With experience in managing complex medical conditions in children",
    social: [
      { icon: "fa6-brands:facebook-f", href: "/about" },
      { icon: "fa6-brands:linkedin-in", href: "/about" },
      { icon: "fa6-brands:twitter", href: "/about" },
    ],
  },
];

const galleryData = [
  { imgUrl: "/images/about/portfolio_2_lg.jpeg" },
  { imgUrl: "/images/about/portfolio_3_lg.jpeg" },
  { imgUrl: "/images/about/portfolio_1_lg.jpeg" },
  { imgUrl: "/images/about/portfolio_4_lg.jpeg" },
  { imgUrl: "/images/about/portfolio_5_lg.jpeg" },
];
const awardData = [
  {
    iconUrl: "/images/icons/award.svg",
    title: "Malcolm Baldrige National Quality Award",
  },
  { iconUrl: "/images/icons/award.svg", title: "HIMSS Davies Award" },
  {
    iconUrl: "/images/icons/award.svg",
    title: "Healthgrades National’s Best Hospital",
  },
  {
    iconUrl: "/images/icons/award.svg",
    title: "Joint Commission Gold Seal of Approval",
  },
];

export default function About() {
  pageTitle("About");
  return (
    <>
      <BannerSectionStyle3
        bgUrl="/images/about/banner_bg.svg"
        imgUrl="/images/about/bannerImg.png"
        title="ABC Billings is a trusted partner for healthcare providers."
        subTitle=" We understand the unique challenges faced by medical practices and are dedicated to helping you overcome them."
      />
      <Section topMd={200} topLg={150} topXl={110}>
        <DepartmentSectionStyle2
          sectionTitle="Provides Our Best Services"
          sectionTitleUp="SERVICES"
          data={departmentData}
        />
      </Section>
      <Section topMd={175} topLg={125} topXl={85} bottomMd={100} bottomLg={110}>
        <FeaturesSectionStyle2
          sectionTitle="Why Choose Us"
          imgUrl="images/about/bannerImg2.png"
          data={featureListData}
        />
      </Section>
      {/* <Section>
        <FunFactSection
          bgUrl="images/about/fun_fact_bg.jpeg"
          data={funFactData}
        />
      </Section>
      <Section topMd={190} topLg={145} topXl={105}>
        <TeamSection
          sectionTitle="Experts Doctor"
          sectionTitleUp="MEET OUR"
          data={teamData}
        />
      </Section>
      <Section topMd={170} topLg={120} topXl={80}>
        <GallerySection
          sectionTitle="Our Facilities and <br />Latest Activities"
          sectionTitleUp="HAVE A LOOK AT"
          data={galleryData}
        />
      </Section>
      <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <AwardSectionStyle2
          sectionTitle="Winning Awards and <br />Recognition"
          sectionTitleUp="AWARDS"
          sectionSubTitle="We have been recognized for our commitment to <br />excellence in healthcare."
          data={awardData}
        />
      </Section> */}
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle4
          bgUrl="images/about/bannerImg3.png"
          title="Explore how our tailored solutions can benefit your practice."
          subTitle="your success is our priority. Learn more about our services and how we can help your practice thrive. Contact us to get started!"
          center
        />
      </Section>
    </>
  );
}
