export default function PrivacyPolicy() {
  return (
    <div className="container privacyPolicy">
      <h4>Privacy Policy</h4>
      {/* Effective Date: [Insert Date] */}
      <p>
        ABC Billings is committed to protecting your privacy. This Privacy
        Policy outlines how we collect, use, and safeguard your information,
        including compliance with HIPAA and HITECH.
      </p>{" "}
      <ul className="privacyList">
        <li>
           <b>Information</b> We Collect We collect various types of information,
          including: Personal Information: Name, contact information, and
          billing details. Health Information: As required for providing our
          medical billing services, including patient records. Technical Data:
          IP address, browser type, and usage data.
        </li>{" "}
        <li>
           <b>How We Use Your Information</b> We use your information to: Provide and
          manage our services. Process transactions and handle billing. Improve
          our services and develop new offerings. Communicate with you about
          updates, offers, and other information related to our services.{" "}
        </li>
        <li>
          <b>HIPAA Compliance</b> ABC Billings is committed to complying with the
          Health Insurance Portability and Accountability Act (HIPAA). HIPAA
          establishes standards for the protection of health information. Our
          practices include: Safeguarding Protected Health Information (PHI): We
          implement physical, administrative, and technical safeguards to
          protect PHI against unauthorized access or disclosure. Confidentiality
          Agreements: Our staff and third-party service providers sign
          confidentiality agreements to ensure the protection of your health
          information. Access Controls: We limit access to PHI to authorized
          personnel only, based on their job requirements.{" "}
        </li>
        <li>
           <b>HITECH Act Compliance</b> Under the Health Information Technology for
          Economic and Clinical Health (HITECH) Act, we are committed to:
          Enhanced Security Measures: Implementing measures to strengthen the
          security of electronic health information and notify affected
          individuals in the event of a data breach. Patient Rights: Providing
          you with access to your health information and the ability to request
          amendments or corrections to your records. Business Associate
          Agreements (BAAs): Entering into agreements with third-party vendors
          who handle PHI on our behalf, ensuring they adhere to HITECH security
          and privacy requirements.
        </li>{" "}
        <li>
          <b>Data Sharing and Disclosure</b> We do not sell your personal
          information. We may share your information with: Service Providers:
          Third parties that assist us in providing our services, under Business
          Associate Agreements where required by HIPAA. Legal Authorities: When
          required by law or to protect our legal rights. Business Transfers: In
          the event of a merger, acquisition, or other business transition.
        </li>{" "}
        <li>
          <b>Data Security</b> We implement reasonable security measures to protect
          your information. However, no data transmission over the Internet or
          electronic storage system can be guaranteed to be 100% secure.{" "}
        </li>
        <li>
           <b>Your Rights You have the right to Access and Amend</b> Access your
          personal and health information and request amendments. Privacy
          Complaints: File a complaint with us or the Department of Health and
          Human Services (HHS) if you believe your privacy rights have been
          violated. Opt-Out: Opt-out of receiving marketing communications from
          us. To exercise these rights, please contact us using the details
          provided below.{" "}
        </li>
        <li>
           <b>Cookies and Tracking Technologies</b> We use cookies and similar
          technologies to enhance your experience on our website. You can manage
          cookie settings through your browser.
        </li>{" "}
        <li>
          <b>Third-Party Links</b> Our website may contain links to third-party
          sites. We are not responsible for the privacy practices or content of
          those sites.
        </li>
        <li>
          {" "}
          <b>Changes to Privacy Policy</b> We may update this Privacy Policy from
          time to time. We will notify you of any significant changes. Your
          continued use of our services after changes constitutes acceptance of
          the revised policy.{" "}
        </li>{" "}
        <li>
        <b>Contact Us</b> For any questions or concerns about this Privacy
          Policy, including HIPAA and HITECH compliance.
          Please contact us at: <b>info@abcbillings.com</b>
        </li>
      </ul>
    </div>
  );
}
