import React from "react";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import MenuWidget from "../Widget/MenuWidget";
import SocialWidget from "../Widget/SocialWidget";
import Newsletter from "../Widget/Newsletter";
import TextWidget from "../Widget/TextWidget";
const menuDataOne = [
  { title: "Home", href: "/" },
  { title: "About", href: "/about" },
  { title: "Services", href: "/departments" },
  // { title: 'Doctors', href: '/doctors' },
  // { title: 'Timetable', href: '/timetable' },
  // { title: 'Appointment', href: '/appointments' },
  // { title: 'Testimonials', href: '/' },
  { title: "Contact Us", href: "/contact" },
];
const menuDataTwo = [
  { title: "FAQs", href: "/" },
  { title: "Privacy Policy", href: "/privacy-policy" },
  { title: "Terms and Conditions", href: "/terms-conditions" },
];

export default function Footer() {
  return (
    <footer className="cs_footer cs_style_1 cs_heading_color">
      <div
        className="cs_footer_logo_wrap"
        style={{ backgroundImage: "url(/images/footer_bg_1.svg)" }}
      >
        <div
          className="cs_footer_brand"
          style={{ backgroundImage: "url(/images/footer_logo_bg.svg)" }}
        >
          <img
            src="/images/logo/logoSm.svg"
            alt="Logo Icon"
            className="cs_footer_brand_icon"
          />
          {/* <h2 className="cs_footer_brand_text">ABC Billings</h2>
           */}
          <img
            src="/images/logo/logoLg.svg"
            alt="Logo Icon"
            // className="cs_footer_brand_icon"
          />
        </div>
      </div>
      <div className="cs_footer_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="cs_footer_item">
                <img src="/images/logo/logo.svg" alt="Logo Icon" />
                <TextWidget text="We are dedicated to optimizing your healthcare revenue cycle with cutting-edge solutions tailored to your practice’s unique needs." />
                <ContactInfoWidget />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="cs_footer_item">
                <MenuWidget data={menuDataOne} />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="cs_footer_item">
                <MenuWidget data={menuDataTwo} />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="cs_footer_item">
                <Newsletter
                  title="Be Our Subscribers"
                  subTitle="To get the latest news about ABC billings from our experts"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cs_footer_bottom cs_accent_bg">
        <div className="container">
          <div className="cs_footer_bottom_in">
            <SocialWidget />
            <div className="cs_copyright">
              Copyright © 2024 ABC Billings. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
