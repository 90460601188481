import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";

export default function SocialWidget() {
  return (
    <div className="cs_social_links_wrap">
      <h2>Follow Us</h2>
      <div className="cs_social_links">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.facebook.com/profile.php?id=61564311505018&mibextid=ZbWKwL"
        >
          <Icon icon="fa-brands:facebook-f" />
        </a>
        {/* <Link to="/">
          <Icon icon="fa-brands:youtube" />
        </Link>
        <Link to="/">
         
        </Link> */}
          <a
          target="_blank"
          rel="noreferrer"
          href="https://www.linkedin.com/company/abc-billings"
        >
          <Icon icon="fa-brands:linkedin-in" />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://x.com/llc_abc6048?s=09"
        >
          <Icon icon="fa-brands:twitter" />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.instagram.com/abcbillings_llc?igsh=Yjgzc3JtcTZxc3Vj"
        >
          <Icon icon="fa-brands:instagram" />
        </a>
      </div>
    </div>
  );
}
