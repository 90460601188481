import React from "react";
import { useState } from "react";

export default function ContactForm() {
  const [contact, setcontact] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [mailStatus, setMailStatus] = useState(false);
  const handleFormInput = (e) => {
    const { name, value } = e.target;
    setcontact({ ...contact, [name]: value });
  };
  const handleContactSubmit = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    setMailStatus(true);
  };
  return (
    <div className="cs_contact_form cs_style_1 cs_white_bg cs_radius_30">
      {!mailStatus ? (
        <form onSubmit={handleContactSubmit}>
          <div className="row">
            <div className="col-lg-6">
              <label className="cs_input_label cs_heading_color">Name</label>
              <input
                type="text"
                name="name"
                className="cs_form_field"
                placeholder="David John"
                value={contact.name}
                onChange={handleFormInput}
                required
              />
              <div className="cs_height_42 cs_height_xl_25" />
            </div>
            <div className="col-lg-6">
              <label className="cs_input_label cs_heading_color">Email</label>
              <input
                type="email"
                name="email"
                className="cs_form_field"
                placeholder="example@gmail.com"
                value={contact.email}
                onChange={handleFormInput}
                required
              />
              <div className="cs_height_42 cs_height_xl_25" />
            </div>
            <div className="col-lg-12">
              <label className="cs_input_label cs_heading_color">Subject</label>
              <input
                type="text"
                className="cs_form_field"
                placeholder="Your subject"
                name="subject"
                value={contact.subject}
                onChange={handleFormInput}
                required
              />
              <div className="cs_height_42 cs_height_xl_25" />
            </div>
            <div className="col-lg-12">
              <label className="cs_input_label cs_heading_color">Message</label>
              <textarea
                cols={30}
                rows={10}
                className="cs_form_field"
                placeholder="Write something..."
                value={contact.message}
                name="message"
                onChange={handleFormInput}
                required
              />
              <div className="cs_height_42 cs_height_xl_25" />
            </div>
            <div className="col-lg-12">
              <div className="cs_height_18" />
              <button type="submit" className="cs_btn cs_style_1">
                <span>Submit</span>
                <i>
                  <img src="/images/icons/arrow_white.svg" alt="Icon" />
                  <img src="/images/icons/arrow_white.svg" alt="Icon" />
                </i>
              </button>
            </div>
          </div>
        </form>
      ) : (
        <h4>Your query is submitted our agent will get back to you shortly...</h4>
      )}
    </div>
  );
}
