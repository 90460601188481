import React from "react";
import Hero from "../Hero";
import AboutSection from "../Section/AboutSection";
import BrandsSection from "../Section/BrandsSection";
import Banner from "../Section/BannerSection";
import Section from "../Section";
import FeaturesSection from "../Section/FeaturesSection";
import TestimonialSection from "../Section/TestimonialSection";
import BlogSection from "../Section/BlogSection";
import AppointmentSection from "../Section/AppointmentSection";
import FaqSection from "../Section/FaqSection";
import AwardSection from "../Section/AwardSection";
import DepartmentSection from "../Section/DepartmentSection";
import { pageTitle } from "../../helpers/PageTitle";
const featureListData = [
  {
    iconSrc: "/images/home_1/compassion.svg",
    title: "Intergrity",
    subTitle:
      "We understand that seeking medical care can be a stressful and emotional experience, and we strive to create a welcoming and supportive environment that puts our patients at ease and every one.",
  },
  {
    iconSrc: "/images/home_1/excellence.svg",
    title: "Innovation",
    subTitle:
      "We are committed to providing excellent medical care and services to our patients. We believe in continuously improving our skills, knowledge, and resources to ensure that we deliver the highest quality care possible.",
  },
  {
    iconSrc: "/images/home_1/respect.svg",
    title: "Client-Centricity",
    subTitle:
      "We believe in practicing medicine with integrity and honesty. We are transparent in our communication and decision-making processes, and we always put our patient's interests first & provide best solution.",
  },
  {
    iconSrc: "/images/home_1/integrity.svg",
    title: "Respect",
    subTitle: `We treat all individuals with respect
and dignity, regardless of their
background, beliefs, or
circumstances. We believe that
every person deserves to be treated
with compassion and kindness.`,
  },

  {
    iconSrc: "/images/home_1/teamwork.svg",
    title: "Teamwork",
    subTitle: `We believe in working
collaboratively with our team
members and other healthcare
professionals to provide
comprehensive and effective care
to our patients.`,
  },
];
const brandData = [
  { imgUrl: "images/brand_1.png", imgAlt: "Brand" },
  { imgUrl: "images/brand_2.png", imgAlt: "Brand" },
  { imgUrl: "images/brand_3.png", imgAlt: "Brand" },
  { imgUrl: "images/brand_4.png", imgAlt: "Brand" },
  { imgUrl: "images/brand_5.png", imgAlt: "Brand" },
  { imgUrl: "images/brand_6.png", imgAlt: "Brand" },
  { imgUrl: "images/brand_7.png", imgAlt: "Brand" },
  { imgUrl: "images/brand_8.png", imgAlt: "Brand" },
];
const faqData = [
  {
    title: "DO I CONSISTENTLY WORK DIRECTLY WITH MANAGEMENT?",
    content:
      "Yes. At ABC BILLINGS, we prioritize personalized service and open communication. When you partner with us for medical billing services, you will consistently have direct access to our management team.",
  },
  {
    title: "WHAT ARE THE BENEFITS OF OUTSOURCING MY MEDICAL BILLING NEEDS?",
    content:
      "By outsourcing your medical billing processes, you can expect improved revenue cycles, reduced claim denials, faster processing, and increased overall efficiency. Our expertise and personalized solutions will allow you to focus more on patient care while we handle your medical billing needs.",
  },

  {
    title: "DO YOU WORK WITH ALL TYPES OF PRACTICES?",
    content:
      "Absolutely. ABC billing's medical billing is equipped to support healthcare practices of all sizes and specialties. Whether you run a small practice, a specialized medical center, or a larger healthcare facility, our services are tailored to suit your unique needs.",
  },
  {
    title: "DO YOU STAY IN COMPLIANCE WITH ALL OF THE BILLING REGULATIONS?",
    content:
      "At ABC billings, patient data security is of the utmost importance. We adhere to strict HIPAA compliance standards and implement industry-leading security measures to protect the confidentiality and privacy of patient information.",
  },
  {
    title: "HOW CAN I GET STARTED WITH ABC Billing'S MEDICAL BILLING SERVICES?",
    content:
      "It's simple! Contact us today for a free consultation. Our team will assess your needs and provide you with a tailored solution that aligns with your goals.",
  },
];
const blogData = [
  {
    title: "The Benefits of Mindfulness Meditation for Stress and Anxiety",
    thumbUrl: "images/home_1/post_1.jpeg",
    date: "May 1, 2023",
    btnText: "Learn More",
    href: "/blog/blog-details",
    socialShare: true,
  },
  {
    title: "Healthy Eating on a Budget: Tips and Strategies",
    thumbUrl: "images/home_1/post_2.jpeg",
    date: "May 4, 2023",
    btnText: "Learn More",
    href: "/blog/blog-details",
    socialShare: true,
  },
  {
    title: "The Importance of Regular Cancer Screenings and Early Detection",
    thumbUrl: "images/home_1/post_3.jpeg",
    date: "May 1, 2023",
    btnText: "Learn More",
    href: "/blog/blog-details",
    socialShare: true,
  },
];
const awardData = [
  {
    title: "Small Practices",
    subTitle:
      "We’re here to support you. Our intuitive, comprehensive solutions for practice management, electronic health records, and revenue cycle management will help your small practice thrive, allowing you to concentrate on what truly matters – providing excellent care for your patients.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Large Medical Group",
    subTitle:
      "Large medical groups utilize our cutting-edge, scalable solutions to expand patient care, accelerate payment processing, and stay ahead of industry shifts, ransitioning to a pay-for-performance model, or aiming for business growth, ABC Billings offers the flexibility required for your success.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Health System",
    subTitle:
      "ABC Billings robust software suite allows health systems to create smooth integrations between providers. Our all-encompassing services manage your administrative, clinical, and financial workflows, enabling you to focus on delivering excellent patient care and succeeding in today’s evolving environment.",
    iconUrl: "images/icons/award.svg",
  },
  {
    title: "Specialities",
    subTitle:
      "Our specialty has distinct requirements for medical billing, practice management, and EHR solutions. We address these needs by enhancing your practice’s performance—across financial, clinical, and patient aspects—through our comprehensive cloud-based solutions.",
    iconUrl: "images/icons/award.svg",
  },
];
const departmentData = [
  {
    title: "Revenue Cycle Managemet",
    iconUrl: "images/home_1/department_icon_1.svg",
    href: "/services/revenue-cycle-management-rcm",
  },
  {
    title: "Practice Management",
    iconUrl: "images/home_1/department_icon_2.svg",
    href: "/services/practice-management",
  },
  {
    title: "Electroni Health Record",
    iconUrl: "images/home_1/department_icon_3.svg",
    href: "/services/electronic-health-records-ehr",
  },
  {
    title: "Healthcare Analytics",
    iconUrl: "images/home_1/department_icon_4.svg",
    href: "/services/healthcare-analytics",
  },
  // {
  //   title: "Professional Services",
  //   iconUrl: "images/home_1/department_icon_5.svg",
  //   href: "/departments/department-details",
  // },
  // {
  //   title: "Psychiatry Department",
  //   iconUrl: "images/home_1/department_icon_6.svg",
  //   href: "/departments/department-details",
  // },
];

export default function Home() {
  pageTitle("Home");
  return (
    <>
      <Hero
        title="Welcome to ABC Billings"
        subTitle="Your Partner in Streamlined Medical Billing Solutions. We are dedicated to optimizing your healthcare revenue cycle with cutting-edge solutions tailored to your practice’s unique needs."
        bgUrl="/images/home_1/hero_bg.jpeg"
        imgUrl="/images/landing/heroImg.png"
        videoBtnText="See how we work"
        videoUrl="https://www.youtube.com/embed/VcaAVWtP48A"
        infoList={[
          {
            title: "Hotline",
            subTitle: "123-456-7890",
            iconUrl: "/images/contact/icon_1.svg",
          },
          {
            title: "Ambulance",
            subTitle: "876-256-876",
            iconUrl: "/images/icons/ambulance.svg",
          },
          {
            title: "Location",
            subTitle: "New York, US",
            iconUrl: "/images/icons/pin.svg",
          },
        ]}
        btnText="Book Now"
        btnUrl="/appointments"
      />
      {/* Start Feature Section */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <FeaturesSection sectionTitle="Our Values" data={featureListData} />
      </Section>
      {/* End Feature Section */}
      {/* Start About Section */}
      <Section>
        <AboutSection
          imgUrl="/images/landing/heroImg2.png"
          spiningImgUrl="/images/landing/about_mini.svg"
          title="About Us"
          subTitle="ABC Billings"
          featureList={[
            {
              featureListTitle:
                "ABC Billings Your Partner in Streamlined Medical Billing Solutions",
              featureListSubTitle:
                "we are dedicated to optimizing your healthcare revenue cycle with cutting-edge solutions tailored to your practice’s unique needs. Our comprehensive suite of services ensures efficient medical billing and revenue management, allowing you to focus on what matters most: delivering exceptional patient care.",
            },
          ]}
        />
      </Section>
      {/* End About Section */}
      {/* Start Departments Section */}
      <Section topMd={185} topLg={150} topXl={110}>
        <DepartmentSection
          sectionTitle="Services"
          bgUrl="images/home_1/department_bg.svg"
          data={departmentData}
        />
      </Section>

      {/* End Departments Section */}
      {/* Start Award Section */}
      <Section topMd={185} topLg={140} topXl={100}>
        <AwardSection sectionTitle="Billing Practices" data={awardData} />
      </Section>
      {/* End Award Section */}
      {/* Start Testimonial */}
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSection
          sectionTitle="Some Reviews"
          sectionTitleDown="Of our clients"
        />
      </Section>
      {/* End Testimonial */}
      {/* Start Banner Section */}
      <Section>
        <Banner
          bgUrl="images/home_1/cta_bg.svg"
          imgUrl="images/landing/heroImg3.png"
          title="We handle the details so you can focus on your well-being"
          subTitle="Your health is our priority. Never let it take a backseat! At ABC Billing, we streamline the billing process so you can focus on what matters most your well-being. Trust us to handle the details while you take care of yourself."
        />
      </Section>
      {/* End Banner Section */}
      {/* Start Blog Section */}
      {/* <Section topMd={190} topLg={145} topXl={105}>
        <BlogSection
          sectionTitle="Latest Update"
          sectionTitleUp="BLOG POSTS"
          data={blogData}
        />
      </Section> */}
      {/* End Blog Section */}
      {/* Start Appointment Section */}
      {/* <Section topMd={190} topLg={145} topXl={105} id="appointment">
        <AppointmentSection
          sectionTitle="Appointment"
          sectionTitleUp="BOOK AN"
          imgUrl="/images/home_1/appointment.jpeg"
        />
      </Section> */}
      {/* End Appointment Section */}
      {/* Start FAQ Section */}
      <Section topMd={190} topLg={145} topXl={105}>
        <FaqSection
          data={faqData}
          sectionTitle="Usually Asked"
          sectionTitleUp="What People"
        />
      </Section>
      {/* End FAQ Section */}
      {/* Start Brand Section */}
      <Section
        topMd={200}
        topLg={150}
        topXl={110}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        {/* <BrandsSection data={brandData} /> */}
      </Section>
      {/* End Brand Section */}
    </>
  );
}
