import React from "react";
import BannerSectionStyle7 from "../Section/BannerSection/BannerSectionStyle7";
import Section from "../Section";
// import AppointmentSection from '../Section/AppointmentSection';
import BreadcrumbStyle2 from "../Breadcrumb/BreadcrumbStyle2";
// import { useParams } from 'react-router-dom';

import AboutSectionStyle2 from "../Section/AboutSection/AboutSectionStyle2";
// import FeaturesSectionStyle3 from '../Section/FeaturesSection/FeaturesSectionStyle3';
// import TeamSectionStyle3 from '../Section/TeamSection/TeamSectionStyle3';
import { pageTitle } from "../../helpers/PageTitle";
import { useParams } from "react-router-dom";

const featureData = [
  {
    title: "Vaccinations Department",
    subTitle:
      "The Pediatric Department provides vaccinations to help protect children from a range of illnesses and diseases, including measles.",
    iconUrl: "/images/departments/icon_9.svg",
  },
  {
    title: "Management of acute illnesses",
    subTitle:
      "The Pediatric Department provides treatment for common childhood illnesses, such as ear infections, strep throat, and viral infections.",
    iconUrl: "/images/departments/icon_10.svg",
  },
  {
    title: "Treatment of chronic conditions",
    subTitle:
      "The Pediatric Department provides ongoing care and treatment for children with chronic conditions such as asthma, diabetes, and allergies.",
    iconUrl: "/images/departments/icon_11.svg",
  },
  {
    title: "Developmental screenings",
    subTitle:
      "The Pediatric Department provides regular developmental screenings to identify any delays or concerns and provide early intervention services.",
    iconUrl: "/images/departments/icon_12.svg",
  },
];

// const doctorData = [
//   {
//     imgUrl: "/images/departments/related_doctor_1.jpeg",
//     name: "Dr. Susan Bones, MD",
//     designation: "Board-certified Pediatrician",
//     description:
//       "With experience in managing complex <br />medical conditions in children",
//     social: [
//       { icon: "fa6-brands:facebook-f", href: "/about" },
//       { icon: "fa6-brands:linkedin-in", href: "/about" },
//       { icon: "fa6-brands:twitter", href: "/about" },
//     ],
//     availableUrl: "/",
//     callUrl: "/",
//     chatUrl: "/",
//     btnText: "Booking",
//     btnUrl: "/appointments",
//   },
//   {
//     imgUrl: "/images/departments/related_doctor_1.jpeg",
//     name: "Dr. Susan Bones, MD",
//     designation: "Board-certified Pediatrician",
//     description:
//       "With experience in managing complex <br />medical conditions in children",
//     social: [
//       { icon: "fa6-brands:facebook-f", href: "/about" },
//       { icon: "fa6-brands:linkedin-in", href: "/about" },
//       { icon: "fa6-brands:twitter", href: "/about" },
//     ],
//     availableUrl: "/",
//     callUrl: "/",
//     chatUrl: "/",
//     btnText: "Booking",
//     btnUrl: "/",
//   },
//   {
//     imgUrl: "/images/departments/related_doctor_1.jpeg",
//     name: "Dr. Susan Bones, MD",
//     designation: "Board-certified Pediatrician",
//     description:
//       "With experience in managing complex <br />medical conditions in children",
//     social: [
//       { icon: "fa6-brands:facebook-f", href: "/about" },
//       { icon: "fa6-brands:linkedin-in", href: "/about" },
//       { icon: "fa6-brands:twitter", href: "/about" },
//     ],
//     availableUrl: "/",
//     callUrl: "/",
//     chatUrl: "/",
//     btnText: "Booking",
//     btnUrl: "/",
//   },
//   {
//     imgUrl: "/images/departments/related_doctor_1.jpeg",
//     name: "Dr. Susan Bones, MD",
//     designation: "Board-certified Pediatrician",
//     description:
//       "With experience in managing complex <br />medical conditions in children",
//     social: [
//       { icon: "fa6-brands:facebook-f", href: "/about" },
//       { icon: "fa6-brands:linkedin-in", href: "/about" },
//       { icon: "fa6-brands:twitter", href: "/about" },
//     ],
//     availableUrl: "/",
//     callUrl: "/",
//     chatUrl: "/",
//     btnText: "Booking",
//     btnUrl: "/",
//   },
// ];

export default function DepartmentDetails() {
  pageTitle("Department Details");
  const id = useParams();
  const data = [
    {
      page: "medical-billing-and-coding",
      heading: "Medical Billing and Coding",
      details:
        "Medical billing and coding is the cornerstone of any healthcare revenue cycle. This service involves translating healthcare services, procedures, and diagnoses into standardized codes that are used to bill insurance companies. Our team of certified coders ensures that every medical procedure and diagnosis is accurately coded according to the latest coding standards, such as ICD-10, CPT, and HCPCS.We handle the entire billing process from start to finish, beginning with patient data entry and insurance verification. Claims are meticulously prepared, reviewed, and submitted electronically to the appropriate payers. Our coders are trained to spot and correct errors before claims are submitted, significantly reducing the chances of denials and rejections.Once a claim is submitted, we track its progress, following up with insurance companies to resolve any issues that may arise. If a claim is denied or underpaid, our team immediately steps in to appeal and resubmit the claim, ensuring you receive the maximum reimbursement.Our service also includes regular audits to identify coding errors and ensure compliance with ever-evolving regulations. We provide detailed reporting, so you always know the status of your billing operations. With our comprehensive medical billing and coding services, you can rest assured that your claims are accurate, compliant, and processed efficiently, leading to faster payments and improved revenue.",
    },
    {
      page: "insurance-verification-and-authorizations",
      heading: "Insurance Verification and Authorizations",
      details:
        "Insurance verification and authorization are critical steps in the revenue cycle management process. This service ensures that the patient’s insurance coverage is verified before any medical services are provided, preventing delays or denials in payment.Our team starts by gathering the necessary patient information, including insurance details. We contact the insurance company to verify coverage, confirming that the patient’s policy is active and determining what services are covered under their plan. This step is crucial to avoid any surprises for both the patient and the provider when the claim is processed.We also handle the pre-authorization process, obtaining the necessary approvals from insurance companies for specific procedures, surgeries, or treatments that require prior consent. This reduces the risk of claims being denied due to lack of authorization and ensures that providers are compensated for their services.By managing the insurance verification and authorization process, we help minimize the financial risk to your practice. Patients are informed upfront about their coverage and any out-of-pocket costs, leading to a smoother billing process. Our thorough approach ensures that you can focus on delivering quality care without worrying about potential payment issues.",
    },
    {
      page: "patient-billing-services",
      heading: "Patient Billing Services",
      details:
        "Patient Billing Services are a crucial aspect of healthcare administration, focusing on managing the financial transactions between healthcare providers and patients. These services involve the generation of bills for the services provided, processing payments, managing payment plans, and handling any billing inquiries or disputes. In the U.S., where healthcare billing is complex due to the involvement of multiple payers, including insurance companies and government programs, Patient Billing Services are essential for ensuring that patients are billed accurately and fairly. Effective Patient Billing Services require a deep understanding of medical coding, insurance policies, and regulatory requirements. These services also involve managing patient communications, such as sending out bills, reminders for overdue payments, and explanations of benefits (EOBs). Additionally, Patient Billing Services often include assistance with setting up payment plans for patients who are unable to pay their bills in full. By providing clear and transparent billing information, these services help reduce confusion and enhance patient satisfaction. Furthermore, efficient billing processes can significantly improve a healthcare provider's cash flow and reduce the likelihood of unpaid bills. Ultimately, Patient Billing Services are about ensuring that the financial side of patient care is handled smoothly, allowing healthcare providers to focus on delivering high-quality care.",
    },
    {
      page: "compliance-and-regulatory-services",
      heading: "Compliance and Regulatory Services",
      details:
        "Compliance and regulatory services are essential for ensuring that your practice adheres to the myriad of laws, regulations, and guidelines governing the healthcare industry. Our team of compliance experts stays up-to-date with the latest changes in healthcare regulations, so you don’t have to.We begin by conducting a thorough assessment of your current billing practices, identifying any areas where compliance may be lacking. Our experts then implement procedures and protocols to ensure your practice meets all federal, state, and industry-specific regulations, including HIPAA, Medicare, and Medicaid guidelines.Regular audits are conducted to review your billing operations, ensuring that claims are coded correctly and submitted in accordance with legal requirements. We also provide training for your staff to help them understand and comply with these regulations, reducing the risk of costly fines or penalties.Our service includes monitoring for updates in healthcare legislation and industry standards. We proactively adjust your billing practices to comply with any new regulations, ensuring that your practice remains in good standing with all regulatory bodies.By partnering with us for compliance and regulatory services, you can safeguard your practice against legal and financial risks. Our commitment to maintaining the highest standards of compliance ensures that you can focus on providing care with confidence, knowing that your billing operations are secure and compliant.",
    },
    {
      page: "revenue-cycle-management-rcm",
      heading: "Revenue Cycle Management (RCM)",
      details:
        "Revenue Cycle Management (RCM) is the financial process that healthcare providers use to track patient care episodes from registration and appointment scheduling to the final payment of a balance. RCM involves several critical steps, including insurance verification, patient eligibility, coding, claims submission, payment posting, denial management, and patient collections. Efficient RCM ensures that healthcare providers receive timely and accurate payments for the services they provide, reducing the chances of claim denials and ensuring financial stability. RCM also involves continuous monitoring of key performance indicators (KPIs) such as days in accounts receivable, clean claim rates, and denial rates. By leveraging advanced technology and data analytics, healthcare providers can streamline their RCM processes, minimize administrative burdens, and improve revenue capture. Effective RCM also enhances patient satisfaction by providing transparent billing processes and reducing billing errors. In the U.S., where healthcare billing is highly complex due to various insurance plans and regulations, RCM plays a crucial role in maintaining the financial health of medical practices. Ultimately, RCM is about optimizing the revenue flow for healthcare providers while ensuring compliance with regulations and maintaining the integrity of patient information.",
    },
    {
      page: "practice-management",
      heading: "Practice Management",
      details:
        "Practice Management is a critical component of any medical practice, focusing on the administration of healthcare services, including appointment scheduling, patient registration, billing, and other administrative tasks. Practice Management systems (PMS) are designed to streamline these operations, ensuring that healthcare providers can focus more on patient care rather than administrative duties. In the U.S., where healthcare regulations are complex, a robust Practice Management system is essential for managing the day-to-day operations of a medical practice efficiently. These systems help in reducing the time spent on paperwork, improving patient flow, and ensuring accurate billing and coding. Practice Management also involves managing staff schedules, ensuring that the right resources are available at the right time to meet patient needs. Additionally, Practice Management systems integrate with Electronic Health Records (EHR) to ensure that patient data is accurately recorded and easily accessible. By automating routine tasks and providing real-time data, Practice Management systems enable healthcare providers to improve their operational efficiency, reduce costs, and enhance patient satisfaction. In essence, Practice Management is about ensuring that the administrative side of healthcare runs smoothly, allowing providers to deliver high-quality care to their patients.",
    },
    {
      page: "electronic-health-records-ehr",
      heading: "Electronic Health Records (EHR)",
      details:
        "Electronic Health Records (EHR) are digital versions of patients' paper charts and play a pivotal role in modern healthcare. EHRs provide real-time, patient-centered records that make information available instantly and securely to authorized users. These records include patients' medical history, diagnoses, medications, treatment plans, immunization dates, allergies, radiology images, and laboratory test results. The adoption of EHRs in the U.S. has revolutionized the way healthcare is delivered, enabling providers to deliver better care by improving accuracy, reducing errors, and facilitating better communication among healthcare teams. EHRs also support the seamless sharing of information across different healthcare settings, ensuring that a patient's data follows them wherever they receive care. Moreover, EHRs contribute to better patient outcomes by providing clinicians with access to evidence-based tools that can assist in decision-making. EHR systems are also designed to automate and streamline provider workflows, reducing the time spent on documentation and allowing for more time with patients. Furthermore, EHRs enhance the accuracy of billing by ensuring that all services provided are documented and coded correctly. In summary, EHRs are an essential tool in modern healthcare, providing a comprehensive and accurate view of patient health while improving the efficiency and quality of care delivery.",
    },
    {
      page: "healthcare-analytics",
      heading: "Healthcare Analytics",
      details:
        "Healthcare Analytics involves the systematic use of data and related business insights developed through statistical, quantitative, contextual, cognitive, and predictive models. In the U.S. healthcare system, where data is abundant and complex, Healthcare Analytics plays a critical role in improving patient care, reducing costs, and enhancing operational efficiency. By analyzing large datasets, healthcare providers can identify trends, predict outcomes, and make data-driven decisions that improve the quality of care. Healthcare Analytics can be applied in various areas, including clinical decision support, population health management, financial management, and operational efficiency. For example, predictive analytics can help identify patients at high risk of developing chronic conditions, allowing for early intervention and better management of these conditions. Healthcare Analytics also helps providers understand patterns in patient care, enabling them to optimize treatment protocols and improve patient outcomes. Additionally, analytics can be used to monitor and improve the efficiency of healthcare operations, such as reducing wait times, optimizing resource allocation, and improving patient flow. In the financial realm, Healthcare Analytics can help providers identify revenue opportunities, reduce claim denials, and improve overall financial performance. Ultimately, Healthcare Analytics empowers healthcare organizations to transform data into actionable insights, driving better clinical and financial outcomes.",
    },
    {
      page: "release-of-information-roi",
      heading: "Release of Information (ROI)",
      details:
        "Release of Information (ROI) is a critical process in the healthcare industry that involves the disclosure of patient health information (PHI) to authorized individuals or entities. This process is governed by strict regulations, such as the Health Insurance Portability and Accountability Act (HIPAA) in the U.S., which ensures that patient privacy is maintained and that PHI is only disclosed to those with the legal right to access it. The ROI process typically involves receiving and validating requests for information, verifying patient consent, retrieving the requested records, and securely delivering the information to the requesting party. ROI services are essential for various purposes, including legal cases, insurance claims, continuing care, and patient access to their own medical records. Effective ROI management requires a thorough understanding of the legal and regulatory requirements surrounding PHI, as well as robust processes to ensure compliance and protect patient confidentiality. Inaccurate or unauthorized disclosure of information can result in significant legal and financial penalties for healthcare providers. Therefore, healthcare organizations often rely on specialized ROI services to handle these requests efficiently and securely. By outsourcing ROI processes, providers can reduce administrative burdens, minimize the risk of errors, and ensure that patient information is handled in accordance with all applicable laws and regulations.",
    },
    {
      page: "clearinghouse-services",
      heading: "Clearinghouse Services",
      details:
        "Clearinghouse Services play a vital role in the healthcare billing process by acting as intermediaries between healthcare providers and insurance payers. These services help streamline the claims submission process by checking for errors, verifying patient insurance coverage, and ensuring that claims are formatted correctly before they are sent to the payer. In the U.S., where the healthcare billing process is highly complex, Clearinghouse Services are essential for reducing the number of denied or rejected claims. By catching errors early in the process, these services help healthcare providers avoid delays in payment and reduce the administrative burden of resubmitting claims. Clearinghouses also provide real-time status updates on claims, allowing providers to track the progress of their submissions and quickly address any issues that arise. Additionally, Clearinghouse Services offer reporting and analytics tools that can help providers identify trends in claim denials and make improvements to their billing processes. By improving the accuracy and efficiency of claims submission, Clearinghouse Services contribute to a more streamlined revenue cycle, ultimately enhancing the financial health of healthcare organizations. In summary, Clearinghouse Services are a critical component of the healthcare billing process, ensuring that claims are processed efficiently and accurately, leading to faster payments and fewer administrative headaches.",
    },
    {
      page: "credentialing-services",
      heading: "Credentialing Services",
      details:
        "Credentialing Services are essential for ensuring that healthcare providers meet the necessary qualifications and standards to deliver care. This process involves verifying a provider's education, training, licensure, certifications, and professional history to ensure that they are qualified to provide medical services. In the U.S., where healthcare is highly regulated, credentialing is a critical step in protecting patient safety and ensuring that providers meet the standards set by insurance companies, hospitals, and other healthcare organizations. Credentialing Services typically involve gathering and verifying documentation, conducting background checks, and ensuring compliance with state and federal regulations. This process is required for providers to participate in insurance networks and receive reimbursement for their services. Additionally, credentialing is an ongoing process, with providers needing to be re-credentialed periodically to ensure that their qualifications remain current. By outsourcing credentialing to specialized services, healthcare organizations can ensure that this complex and time-consuming process is handled efficiently and accurately. Credentialing Services also help providers avoid delays in the reimbursement process and ensure that they remain in good standing with insurance networks and regulatory bodies. Ultimately, Credentialing Services are about ensuring that healthcare providers are qualified, competent, and compliant with all applicable regulations, which is critical for maintaining the trust of patients and the integrity of the healthcare system.",
    },
  ];

  // console.log('page  id ',id.departmentId)
  const content = data.find((det) => det.page === id.departmentId);
  const { heading, details } = content;
  return (
    <>
      <BreadcrumbStyle2 />
      <Section topMd={135} topLg={100} topXl={100}>
        <AboutSectionStyle2
          title={heading}
          subTitle={details}
          imgUrl="/images/departments/department_img_1.png"
        />
      </Section>

      {/* <Section topMd={170} topLg={145} topXl={90}>
        <FeaturesSectionStyle3
          sectionTitle="Treatments"
          sectionTitleUp="MORE TYPE OF"
          data={featureData}
        />
      </Section>
      <Section topMd={200} topLg={150} topXl={100}>
        <TeamSectionStyle3 sectionTitle="Related Doctor" data={doctorData} />
      </Section> */}

      {/* Start Appointment Section */}
      {/* <Section
        topMd={190}
        topLg={145}
        topXl={105}
        bottomMd={190}
        bottomLg={145}
        bottomXl={110}
        id="appointment"
      >
        <AppointmentSection
          sectionTitle="Appointment"
          sectionTitleUp="BOOK AN"
          imgUrl="/images/home_1/appointment.jpeg"
        />
      </Section> */}
      {/* End Appointment Section */}
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle7
          imgUrl="/images/departments/banner_img_3.png"
          bgUrl="/images/departments/banner_bg_3.svg"
          title="Explore how our tailored solutions can benefit your practice."
          subTitle="Our solutions are designed to address every aspect of your revenue cycle and operational needs. Learn more or get in touch with us today!"
        />
      </Section>
    </>
  );
}
