import React from 'react';
import BannerSectionStyle5 from '../Section/BannerSection/BannerSectionStyle5';
import Section from '../Section';
import DepartmentSectionStyle3 from '../Section/DepartmentSection/DepartmentSectionStyle3';
import BannerSectionStyle6 from '../Section/BannerSection/BannerSectionStyle6';
import { pageTitle } from '../../helpers/PageTitle';
const departmentData = [
  {
    iconUrl: 'images/departments/icon_1.svg',
    title: 'Revenue Cycle Management (RCM)',
    subTitle:
      'Optimize your financial performance with our comprehensive RCM solutions, including medical billing, medical coding, and claims management.',
    href: '/services/revenue-cycle-management-rcm',
  },
  {
    iconUrl: 'images/departments/icon_1.svg',
    title: 'Practice Management',
    subTitle:
      'Improve your practice’s operations with our practice management services, covering everything from patient scheduling to billing.',
    href: '/services/practice-management',
  },
  {
    iconUrl: 'images/departments/icon_1.svg',
    title: 'Electronic Health Records',
    subTitle:
      'Implement our advanced EHR systems for seamless patient data management and improved compliance.',
    href: '/services/electronic-health-records-ehr',
  },
  {
    iconUrl: 'images/departments/icon_1.svg',
    title: 'Healthcare Analytics',
    subTitle:
      'Leverage our healthcare analytics to make informed decisions and drive practice growth.',
    href: '/services/healthcare-analytics',
  },
  {
    iconUrl: 'images/departments/icon_1.svg',
    title: 'Release of Information (ROI)',
    subTitle:
      'Ensure accurate and compliant handling of patient data with our release of information services.',
    href: '/services/release-of-information-roi',
  },
  {
    iconUrl: 'images/departments/icon_1.svg',
    title: 'Patient Billing Services',
    subTitle:
      'Transparent and patient-friendly billing statements. Assistance with payment plans and patient inquiries.',
    href: '/services/patient-billing-services',
  },
  {
    iconUrl: 'images/departments/icon_1.svg',
    title: 'Clearinghouse Services',
    subTitle:
      'Streamline your claims process with our efficient clearinghouse solutions.',
    href: '/services/clearinghouse-services',
  },
  {
    iconUrl: 'images/departments/icon_1.svg',
    title: 'Credentialing Services',
    subTitle:
      'Simplify and expedite the credentialing process with our expert credentialing services.',
    href: '/services/credentialing-services',
  },
];

export default function Departments() {
  pageTitle('Departments');
  return (
    <>
      <BannerSectionStyle5
        bgUrl="/images/departments/banner_bg.svg"
        imgUrl="/images/departments/heroImg.png"
        title="Get to Know ABC Billings Services"
        subTitle="At ABC Billings, our full spectrum of services is designed to enhance your practice’s efficiency and profitability"
      />
      <Section bottomMd={140} bottomLg={100} bottomXl={60}>
        <DepartmentSectionStyle3 data={departmentData} />
      </Section>
      <Section className="cs_footer_margin_0">
        <BannerSectionStyle6
          imgUrl="/images/departments/bannerImg2.png"
          title="Explore how our tailored solutions can benefit your practice."
          subTitle="Our solutions are designed to address every aspect of your revenue cycle and operational needs. Learn more or get in touch with us today!"
        />
      </Section>
    </>
  );
}
