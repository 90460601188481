export default function TermsAndConditions() {
  return (
    <div className="container privacyPolicy">
      <h4>Terms and Conditions</h4>
      {/* Effective Date: [Insert Date] */}
      <p>
        Welcome to ABC Billings. By using our services, you agree to comply with
        and be bound by the following Terms and Conditions. Please read them
        carefully. If you do not agree with these terms, please do not use our
        services.
      </p>{" "}
      <ul className="privacyList">
        <li>
          <strong>Acceptance of Terms</strong>
          <br />
          By accessing and using ABC Billings’ services, you accept and agree to
          be bound by these Terms and Conditions and our Privacy Policy.
        </li>

        <li>
          <strong>Services Provided</strong>
          <br />
          ABC Billings provides medical billing and practice management
          services, including but not limited to revenue cycle management,
          electronic health records (EHR), telehealth solutions, healthcare
          analytics, and more.
        </li>

        <li>
          <strong>User Responsibilities</strong>
          <br />
          You agree to:
          <ul>
            <li>
              Provide accurate, current, and complete information when required.
            </li>
            <li>
              Use our services only for lawful purposes and in accordance with
              applicable laws and regulations.
            </li>
            <li>
              Keep your account credentials confidential and notify us
              immediately of any unauthorized use of your account.
            </li>
          </ul>
        </li>

        <li>
          <strong>Payment Terms</strong>
          <br />
          <ul>
            <li>
              <strong>Fees:</strong> The fees for our services will be as
              specified in your service agreement or as otherwise communicated.
            </li>
            <li>
              <strong>Billing:</strong> Payments are due as per the terms
              outlined in your agreement.
            </li>
            <li>
              <strong>Late Payments:</strong> Late payments may incur additional
              charges or result in suspension of services.
            </li>
          </ul>
        </li>

        <li>
          <strong>Confidentiality</strong>
          <br />
          We take reasonable measures to protect your information, but we cannot
          guarantee absolute security. By using our services, you acknowledge
          that you understand the risks associated with online transactions.
        </li>

        <li>
          <strong>Intellectual Property</strong>
          <br />
          All content and materials provided by ABC Billings are the
          intellectual property of ABC Billings. You may not reproduce,
          distribute, or modify any part of our content without our prior
          written consent.
        </li>

        <li>
          <strong>Limitation of Liability</strong>
          <br />
          ABC Billings will not be liable for any indirect, incidental, or
          consequential damages arising out of or related to the use of our
          services. Our liability is limited to the fullest extent permitted by
          law.
        </li>

        <li>
          <strong>Termination</strong>
          <br />
          We reserve the right to terminate or suspend your access to our
          services at our discretion, particularly if you violate these Terms
          and Conditions.
        </li>

        <li>
          <strong>Changes to Terms</strong>
          <br />
          We may update these Terms and Conditions from time to time. We will
          notify you of any significant changes, and your continued use of our
          services constitutes acceptance of the new terms.
        </li>

        <li>
          <strong>Governing Law</strong>
          <br />
          These Terms and Conditions are governed by the laws of the State, without regard to its conflict of laws principles.
        </li>

        <li>
          <strong>Contact Information</strong>
          <br />
          For any questions about these Terms and Conditions, please contact us
          at: <b>info@abcbillings.com</b>
        </li>
      </ul>
    </div>
  );
}
